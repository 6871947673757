import {
  angleIcon,
  applicationsIcon,
  backupRestoreIcon,
  bundleIcon,
  checkCircleIcon,
  circleArrowIcon,
  circleIcon,
  ClarityIcons as Icons,
  clusterIcon,
  copyToClipboardIcon,
  deployIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  eyeHideIcon,
  eyeIcon,
  fileGroupIcon,
  fileIcon,
  heartIcon,
  helpIcon,
  infoCircleIcon,
  plusCircleIcon,
  refreshIcon,
  rewindIcon,
  searchIcon,
  successStandardIcon,
  timesCircleIcon,
  timesIcon,
  trashIcon,
  uploadCloudIcon,
} from "@clr/core/icon";
import "@clr/core/icon/register.js";
import * as React from "react";

import "@clr/ui/clr-ui.min.css";

Icons.addIcons(
  angleIcon,
  bundleIcon,
  checkCircleIcon,
  circleArrowIcon,
  clusterIcon,
  exclamationTriangleIcon,
  eyeHideIcon,
  eyeIcon,
  fileGroupIcon,
  helpIcon,
  infoCircleIcon,
  rewindIcon,
  searchIcon,
  trashIcon,
  uploadCloudIcon,
  applicationsIcon,
  heartIcon,
  exclamationCircleIcon,
  timesIcon,
  deployIcon,
  fileIcon,
  copyToClipboardIcon,
  backupRestoreIcon,
  refreshIcon,
  plusCircleIcon,
  timesCircleIcon,
  uploadCloudIcon,
  successStandardIcon,
  circleIcon,
);
const CSSSelector: React.FC = () => null;

export default CSSSelector;
